var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-tabs',{staticClass:"main-tabs",attrs:{"active-key":_vm.$route.query.tab || 'video',"type":"card","default-active-key":(_vm.$route.query.tab &&
      ['video', 'photo'].includes(_vm.$route.query.tab) &&
      _vm.$route.query.tab) ||
    'video'},on:{"change":_vm.tabPaneChangeHandler}},[_c('a-tab-pane',{key:"video"},[_c('span',{attrs:{"slot":"tab"},slot:"tab"},[_c('a-icon',{attrs:{"type":"video-camera"}}),_vm._v(_vm._s(_vm.$t("Video"))+" ")],1),_c('video-tab-table')],1),_c('span',{attrs:{"slot":"tabBarExtraContent"},slot:"tabBarExtraContent"},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
          name: 'video-create',
          params: { type: _vm.$route.query.tab || 'video' },
          query: { type: _vm.$route.query.tab || 'video' }
        })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(_vm._s(_vm.$t("Add"))+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }